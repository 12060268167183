import React, { useState } from 'react';
import { globalHistory } from '@reach/router';
import WebsiteImage from 'components/WebsiteImage';
import ContentfulRichText from 'components/RichText/ContentfulRichText';

import iconRightArrow from 'images/right-arrow-titan-blue.svg';
import { Container, InnerContainer, StyledButton, ViewAllButton } from './styles';

const Sandpaper = ({ entries = [], options, template }) => {
  const [showAllFeatures, setShowAllFeatures] = useState(false);
  const allFeatures =
    entries?.find((row) => row.executedQuery)?.executedQuery ||
    template?.rows?.find((row) => row?.internalName?.includes('Query'))?.columns?.[0]?.items?.[0]
      ?.executedQuery;
  const category = options?.Category;
  const theme = options?.Theme;
  const currentPath = globalHistory?.location?.pathname;
  const features =
    allFeatures?.find((feature) => feature.internalName.includes(category)) ||
    allFeatures?.[0] ||
    [];
  const featuresToRender = features?.components?.filter((feature) => feature.link !== currentPath);
  const title = entries.filter((entry) => entry.templateId === 'title')?.[0];
  const copy = entries.filter((entry) => entry.templateId === 'copy')?.[0];
  const copyButton = entries.filter((entry) => entry.templateId === 'copy-call-to-action')?.[0];
  const viewAllButton = entries.filter(
    (entry) => entry.templateId === 'view-all-call-to-action',
  )?.[0];
  const isNoButton = !(copy && copyButton) && !viewAllButton;

  return (
    <Container theme={theme}>
      <InnerContainer>
        {title && <ContentfulRichText className="sandpaper--title" richText={title} />}
        {copy && (
          <div className="sandpaper--copy-container min-sm">
            <ContentfulRichText className="sandpaper--copy" richText={copy} />
            {copyButton && (
              <ViewAllButton className="sandpaper--copy-button" to={copyButton.url} theme={theme}>
                {copyButton.buttonLabel}
              </ViewAllButton>
            )}
          </div>
        )}
        {featuresToRender?.length > 0 && (
          <div
            className={`sandpaper--features-container ${isNoButton ? 'no-button' : ''} ${
              showAllFeatures || featuresToRender.length <= 6 ? 'show-all' : ''
            }`}
          >
            {featuresToRender?.map((feature) => {
              return (
                <StyledButton to={feature.link} theme={theme}>
                  {feature.icon && <WebsiteImage className="button-icon" {...feature.icon} />}
                  {feature.title}
                  {!feature.icon && (
                    <img className="button-arrow" src={iconRightArrow} alt="button-arrow" />
                  )}
                </StyledButton>
              );
            })}
          </div>
        )}
        {!copyButton && viewAllButton && (
          <ViewAllButton to={viewAllButton.url} theme={theme}>
            {viewAllButton.buttonLabel}
          </ViewAllButton>
        )}
        {copy && copyButton && (
          <ViewAllButton className="max-sm" to={copyButton.url} theme={theme}>
            {copyButton.buttonLabel}
          </ViewAllButton>
        )}
        {isNoButton && featuresToRender?.length > 6 && !showAllFeatures && (
          <ViewAllButton className="max-sm" onClick={() => setShowAllFeatures(true)} theme={theme}>
            Show All Features
          </ViewAllButton>
        )}
      </InnerContainer>
    </Container>
  );
};

export default Sandpaper;
