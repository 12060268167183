import styled from 'styled-components';
import Button from 'components/Core/Button';

const InnerContainer = styled.div`
  max-width: 1252px;
  padding: 50px 28px;
  margin: 0 auto;
`;

const Container = styled.div`
  background: ${({ theme }) => `var(${theme === 'Dark Theme' ? '--dark' : '--white'})`};

  .sandpaper--title * {
    color: ${({ theme }) => `var(${theme === 'Dark Theme' ? '--white' : '--darkest'})`};
    margin: 0;
  }

  .sandpaper--copy-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 20px;
    margin-top: 50px;

    .sandpaper--copy {
      width: 75%;
      * {
        color: ${({ theme }) => `var(${theme === 'Dark Theme' ? '--white' : '--darkest'})`};
        font-size: var(--font-size-25);
        font-weight: var(--font-weight-400);
        line-height: var(--line-height-150);
        letter-spacing: -0.25px;
        margin: 0;
      }
    }

    .sandpaper--copy-button {
      margin: 0 0 10px;
      width: calc(25% - 15px);
      min-width: 160px;
    }
  }

  .sandpaper--features-container {
    row-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
    display: flex;
    margin-top: 50px;
    position: relative;

    @media (min-width: 576px) and (max-width: 991px) {
      column-gap: 21px;
    }

    @media (max-width: 575px) {
      &.no-button {
        max-height: calc(82px * 6 - 19px);
        overflow: hidden;

        &::after {
          content: ' ';
          position: absolute;
          width: 100%;
          height: 82px;
          bottom: 0;
          z-index: 20;
          background: ${({ theme }) =>
            theme === 'Dark Theme'
              ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, var(--dark) 100%)'
              : 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, var(--white) 100%)'};
        }
      }
      &.show-all {
        max-height: 100%;
        overflow: auto;

        &::after {
          display: none;
        }
      }
    }
  }

  @media (max-width: 575px) {
    .sandpaper--title * {
      text-align: center;
    }
  }
`;

const StyledButton = styled(Button)`
  background: var(--white);
  border-radius: 4px !important;
  border: 1px solid var(--grey-3);
  color: var(--darkest) !important;
  font-size: 16px;
  font-weight: 500;
  height: 72px;
  padding: 15px;
  width: 100%;
  box-shadow: none;
  display: flex;
  justify-content: flex-start;
  line-height: 20px;
  position: relative;

  .button-icon {
    width: 32px;
    margin-right: 20px;
  }

  .button-arrow {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 15px;
  }

  &:hover {
    background: ${({ theme }) =>
      `var(${theme === 'Dark Theme' ? '--grey-3' : '--titan-blue-hover'})`};
    border: 1px solid var(--grey-3);
    color: var(--darkest);

    .button-arrow {
      right: 13px;
    }
  }

  @media (min-width: 992px) {
    width: calc(25% - 15px) !important;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    width: calc(33.33% - 14px) !important;
  }

  @media (min-width: 576px) and (max-width: 767px) {
    height: 90px;
  }

  @media (max-width: 575px) {
    height: 62px;
  }
`;

const ViewAllButton = styled(Button)`
  background: transparent;
  border-radius: 4px !important;
  border: 1px solid ${({ theme }) => `var(${theme === 'Dark Theme' ? '--white' : '--grey-3'})`} !important;
  color: ${({ theme }) => `var(${theme === 'Dark Theme' ? '--white' : '--darkest'})`} !important;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  margin-top: 20px;
  padding: 15px;
  width: 100%;
  box-shadow: none;

  &:hover {
    background: ${({ theme }) =>
      `var(${theme === 'Dark Theme' ? '--grey-8' : '--titan-blue-hover'})`};
  }

  @media (max-width: 575px) {
    margin-top: 30px;
  }
`;

export { Container, InnerContainer, StyledButton, ViewAllButton };
